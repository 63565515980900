// store/channel.js

import { defineStore } from 'pinia';
import { useUserStore } from './user-store.js';

export const useChannelStore = defineStore('channel', {
  state: () => ({
    privateChannel: null,
  }),
  actions: {
    joinPrivateChannel() {
      const userStore = useUserStore();
      const companyId = userStore.company.id;
      const channelName = `company.${companyId}`;

      this.privateChannel = window.Echo.private(channelName);
    },
    leavePrivateChannel() {
      if (this.privateChannel) {
        this.privateChannel = null;
      }
    },
    listenToEvent(eventName, callback) {
      if (this.privateChannel) {
        this.privateChannel.listen(eventName, callback);
      }
    },
    stopListeningToEvent(eventName) {
      if (this.privateChannel) {
        this.privateChannel.stopListening(eventName);
      }
    },
  },
});
