export default async function authMiddleware(context) {
  const { to, next, userStore } = context;

  await userStore.fetchSelf();

  if (userStore.isAuthenticated) {
    if (to.meta.permissions && userStore.isNotSuperAdmin) {
      if (!to.meta.permissions.every((permission) => userStore.user.permissions.includes(permission))) {
        return next({ name: 'error404' });
      }
    }

    if (userStore.isSupport) {
      return (window.location = 'nova/dashboards/main');
    }

    if (userStore.isNotVerified && to.name !== 'MustVerifyEmail') {
      return next({ name: 'MustVerifyEmail' });
    }

    if (to.name === 'MustVerifyEmail' && userStore.isVerified) {
      return next({ name: 'Dashboard' });
    }

    if (userStore.isNotAgency && userStore.isNotOwner && to.name !== 'ContactOwner' && userStore.isNotSuperAdmin) {
      return next({ name: 'ContactOwner' });
    }

    return next();
  } else {
    userStore.returnUrl = to.fullPath;

    return next({ name: 'Login' });
  }
}
