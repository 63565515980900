<template>
  <router-view :key="key" />
</template>

<script setup>
import { onBeforeUnmount, onMounted } from 'vue';
import { useUserStore } from '@/store/user-store.js';
import { useChannelStore } from '@/store/channel-store.js';
import useApp from '@/composables/useApp.js';

const { key } = useApp();
const userStore = useUserStore();
const channelStore = useChannelStore();

onMounted(() => {
  if (userStore.user) {
    channelStore.joinPrivateChannel();
  }
});

onBeforeUnmount(() => {
  channelStore.leavePrivateChannel();
});
</script>
