import guestMiddleware from '@/router/middleware/guest-middleware.js';

/**
 * Routes that do not require authentication.
 * Also includes a catch-all 404 route for any unmatched routes.
 */

export let guestRoutes = [
  {
    name: 'GuestLayout',
    component: () => import('@/layouts/GuestLayout.vue'),
    children: [
      {
        path: '/:pathMatch(.*)*',
        name: 'error404',
        component: () => import('@/views/Errors404NotFound.vue'),
      },
    ],
  },
  {
    name: 'AuthLayout',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        name: 'Login',
        path: '/login',
        component: () => import('@/views/AuthLogin.vue'),
      },
      {
        name: 'Register',
        path: '/register',
        component: () => import('@/views/AuthRegister.vue'),
      },
      {
        name: 'ForgotPassword',
        path: '/forgot-password',
        component: () => import('@/views/AuthForgotPassword.vue'),
      },
      {
        name: 'ResetPassword',
        path: '/password/reset/:token',
        component: () => import('@/views/AuthResetPassword.vue'),
      },
      {
        name: 'VerifyEmail',
        path: '/email/verify',
        component: () => import('@/views/AuthVerifyEmail.vue'),
      },
      {
        name: 'TwoFactorChallenge',
        path: '/two-factor-challenge',
        component: () => import('@/views/AuthTwoFactorChallenge.vue'),
      },
    ],
  },
];

guestRoutes.forEach((layout) => {
  layout.children.forEach((route) => {
    route.meta = {
      ...route.meta,
      middleware: [guestMiddleware],
    };
  });
});
