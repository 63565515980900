/*
 * Base "Global" Components
 */
import App from '@/App.vue';
import { ucFirst } from '@/helpers/index.js';

import PrivateLayout from '@/layouts/AuthLayout.vue';
import PublicLayout from '@/layouts/GuestLayout.vue';

import { HighchartsVue } from '@/plugins/highcharts';
import router from '@/router';

import Maska from 'maska';
import moment from 'moment';
import { createPinia } from 'pinia';
import 'tippy.js/dist/tippy.css'; // optional for styling
import { createApp } from 'vue';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import * as Sentry from '@sentry/vue';
import { createGtm } from '@gtm-support/vue-gtm';
import { install as VueRecaptcha } from 'vue3-recaptcha-v2';

window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: true,
  // wsHost: window.location.hostname,
  // wsPort: 6001,
  // wssPort: 6001,
  // disableStats: true,
  // enabledTransports: ['ws', 'wss'],
});

/*
 * 3rd Party
 */
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { plugin as VueTippy } from 'vue-tippy';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'grapesjs/dist/css/grapes.min.css';
import '../css/app.css';

const pinia = createPinia();
const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/app\.marketvue\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(pinia);
app.use(router);

app.use(
  createGtm({
    id: import.meta.env.VITE_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    // queryParams: {
    //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //   gtm_preview: 'env-4',
    //   gtm_cookies_win: 'x',
    // },
    // source: 'https://customurl.com/gtm.js', // Add your own serverside GTM script
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: import.meta.env.DEV, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  })
);

app.use(VueRecaptcha, {
  sitekey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
});

app.component('App', App);
app.component('PublicLayout', PublicLayout);
app.component('PrivateLayout', PrivateLayout);
app.component('VSelect', vSelect);

app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
  }
);

const toastOptions = {
  position: 'bottom-right',
  maxToasts: 20,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
};

app.use(Toast, toastOptions);
app.use(HighchartsVue);
app.use(Maska);

// vSelect.props.components.default = () => ({ VSelectOpenIndicator });
// app.component('VSelect', vSelect);

/*
 * Directives
 */

app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };

    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

/*
 * Global Mixins
 */
// TODO get these out of here
app.mixin({
  methods: {
    moment: function (date) {
      return moment(date);
    },
    typedValue: function (type, value, typeExtras) {
      if (type === 'text') {
        return value;
      }

      if (type === 'date') {
        return value;
      }

      if (typeExtras) {
        return app.config.globalProperties.$filters[type](value, ...typeExtras);
      }

      return app.config.globalProperties.$filters[type](value);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isCurrency: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];

      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
  },
});

/*
 * Global Filters
 */
app.config.globalProperties.$filters = {
  shortDate(value) {
    return moment(value).format('MM/DD/YYYY');
  },

  sortableDate(value) {
    if (!value) {
      return '';
    }

    return moment(value).format('YYYY-MM-DD');
  },

  campaignDate(value) {
    return moment(value).format('MMM DD[,] YYYY');
  },

  dateAndTime(value) {
    return moment(value).format('MMMM Do YYYY, h:mm:ss a');
  },

  // localDate(date, format = 'YYYY-MM-DD hh:mm:ss A') {
  //   return moment.utc(date).tz(user.value.timezone).format(format);
  // },

  currency(value, decimalPlaces = 2) {
    if (typeof value !== 'number') {
      return value;
    }

    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimalPlaces,
    });

    return formatter.format(value);
  },

  number(value) {
    return Number(value).toLocaleString();
  },

  duration(inputSeconds) {
    inputSeconds = Math.round(inputSeconds);

    const hours = Math.floor(inputSeconds / 3600);
    const minutes = Math.floor((inputSeconds % 3600) / 60);
    const seconds = inputSeconds % 60;

    let parts = [];
    if (hours) parts.push(`${hours}h`);
    if (minutes || hours) parts.push(`${minutes}m`);
    parts.push(`${seconds}s`);

    return parts.join(' ');
  },

  percent(value) {
    value = Math.round(value * 100) / 100;

    if (isNaN(value)) {
      value = 0;
    } else if (value === 'Infinity') {
      value = 0;
    }

    return value + '%';
  },

  phone(phone) {
    return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  },

  ucFirst(string) {
    return ucFirst(string);
  },
};

const vm = app.mount('#app');

export default vm;
