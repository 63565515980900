import router from '@/router';
import { useUserStore } from '@/store/user-store.js';
import axios from 'axios';

const baseURL = '/api';

const ApiClient = axios.create({
  baseURL,
  withCredentials: true,
});

ApiClient.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

ApiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Check if the error should be handled by the interceptor
    if (error.config && error.config.headers['X-Skip-Interceptor']) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 || error.response.status === 419) {
      const userStore = useUserStore();
      userStore.logout();
      return router.push({ name: 'Login' });
    }

    return Promise.reject(error);
  }
);

export function setResponse(type) {
  ApiClient.defaults.responseType = type;
}

export default ApiClient;
