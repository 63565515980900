import middlewarePipeline from '@/router/middleware/middleware-pipeline';
import routes from '@/router/routes';
import { useUserStore } from '@/store/user-store.js';
import qs from 'qs';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
  parseQuery: (query) => {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    return qs.stringify(query, { encode: false });
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    userStore,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
