import CampaignApi from '@/api/campaign.api.js';
import { reactive, ref } from 'vue';

const loading = ref(false);
const fetching = ref(false);
const error = ref(null);
const pagination = ref(null);
const price = ref(null);
const coupon = ref(null);

const campaign = ref(null);
const campaigns = ref([]);
const selectedFilters = reactive({});
const creativeProofPdfPreview = ref(null);

export default function useCampaigns() {
  /**
   * Fetch all campaigns from the API
   *
   * @returns {Promise<*>}
   */
  async function fetchCampaigns(options) {
    fetching.value = true;
    error.value = null;

    try {
      const response = await CampaignApi.getAll(options);
      campaigns.value = response.data.data;
      pagination.value = response.data;

      return response.data;
    } catch (e) {
      error.value = e.response.data;

      throw e;
    } finally {
      fetching.value = false;
    }
  }

  /**
   * Fetch a campaign from the API
   *
   * @param campaignId
   * @returns {Promise<*>}
   */
  async function fetchCampaign(campaignId) {
    fetching.value = true;
    error.value = null;

    try {
      const response = await CampaignApi.getById(campaignId);
      campaign.value = response.data.data;

      return response.data.data;
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      fetching.value = false;
    }
  }

  /**
   * Store a campaign with the given data
   *
   * @param data
   * @returns {Promise<*>}
   */
  async function storeCampaign(data) {
    loading.value = true;
    error.value = null;

    try {
      const response = await CampaignApi.create(data);

      return response.data;
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Update a campaign by ID with the given data
   *
   * @param campaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function updateCampaign(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      await CampaignApi.updateById(campaignId, data);
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Hide campaign by ID
   *
   * @param campaignId
   * @returns {Promise<*>}
   */
  async function hideCampaign(campaignId) {
    error.value = null;

    try {
      const response = await CampaignApi.hideById(campaignId);

      return response.data.data;
    } catch (e) {
      error.value = e;
      throw e;
    }
  }

  /**
   * Delete campaign by ID
   *
   * @param campaignId
   * @returns {Promise<*>}
   */
  async function deleteCampaign(campaignId) {
    error.value = null;

    try {
      const response = await CampaignApi.deleteById(campaignId);

      return response.data.data;
    } catch (e) {
      error.value = e;
      throw e;
    }
  }

  /**
   * Cancel campaign by ID
   *
   * @param campaignId
   * @returns {Promise<*>}
   */
  async function cancelCampaign(campaignId) {
    error.value = null;

    try {
      const response = await CampaignApi.cancelById(campaignId);

      return response.data.data;
    } catch (e) {
      error.value = e;
      throw e;
    }
  }

  async function restoreHiddenCampaign(id) {
    error.value = null;

    try {
      const response = await CampaignApi.restoreById(id);

      return response.data.data;
    } catch (e) {
      error.value = e;
      throw e;
    }
  }

  /**
   * Apply coupon code
   *
   * @param camnpaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function applyCoupon(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      const response = await CampaignApi.applyCouponCode(campaignId, data);
      coupon.value = response.data.data;

      return response.data.data;
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Fetch campaign price from the API
   *
   * @param camnpaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function fetchPrice(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      const response = await CampaignApi.calculatePrice(campaignId, data);
      price.value = response.data.data;
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Fetch campaign creative proof pdf preview from the API
   *
   * @param camnpaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function fetchCreativeProofPreview(campaignId) {
    loading.value = true;
    error.value = null;

    try {
      const response = await CampaignApi.getCreativeProofPreview(campaignId);

      if (response.data.includes('.pdf')) {
        creativeProofPdfPreview.value = response.data;
      } else {
        const blob = base64ToBlob(response.data, 'application/pdf');
        creativeProofPdfPreview.value = URL.createObjectURL(blob);
      }
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  function base64ToBlob(base64, type = 'application/octet-stream') {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }

    return new Blob([arr], { type: type });
  }

  /**
   * Launch campaign
   *
   * @param camnpaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function purchaseCampaign(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      const response = await CampaignApi.purchase(campaignId, data);

      return response.data;
    } catch (err) {
      error.value = err.response;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Update a campaign contact list with the given data
   *
   * @param campaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function updateCampaignContactList(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      await CampaignApi.updateContactList(campaignId, data);
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Update a campaign vanity domain with the given data
   *
   * @param campaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function updateCampaignVanityDomain(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      await CampaignApi.updateVanityDomain(campaignId, data);
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Update a campaign lander with the given data
   *
   * @param campaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function updateCampaignLander(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      await CampaignApi.updateLander(campaignId, data);
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Update a campaign creative with the given data
   *
   * @param campaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function updateCampaignCreative(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      await CampaignApi.updateCreative(campaignId, data);
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Update a campaign return address with the given data
   *
   * @param campaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function updateCampaignReturnAddress(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      await CampaignApi.updateReturnAddress(campaignId, data);
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Update a campaign phone tracking with the given data
   *
   * @param campaignId
   * @param data
   * @returns {Promise<*>}
   */
  async function updateCampaignPhoneTracking(campaignId, data) {
    loading.value = true;
    error.value = null;

    try {
      await CampaignApi.updatePhoneTracking(campaignId, data);
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  async function removeCampaignLander(campaignId) {
    loading.value = true;
    error.value = null;

    try {
      await CampaignApi.deleteLander(campaignId);
    } catch (err) {
      error.value = err;

      throw err;
    } finally {
      loading.value = false;
    }
  }

  return {
    pagination,
    loading,
    fetching,
    error,
    campaign,
    campaigns,
    cancelCampaign,
    fetchCampaign,
    fetchCampaigns,
    updateCampaign,
    fetchPrice,
    price,
    storeCampaign,
    deleteCampaign,
    selectedFilters,
    applyCoupon,
    coupon,
    purchaseCampaign,
    updateCampaignContactList,
    updateCampaignCreative,
    updateCampaignVanityDomain,
    updateCampaignLander,
    updateCampaignReturnAddress,
    updateCampaignPhoneTracking,
    removeCampaignLander,
    fetchCreativeProofPreview,
    creativeProofPdfPreview,
    restoreHiddenCampaign,
    hideCampaign,
  };
}
