import HighchartsVue from 'highcharts-vue';

// Adds support for listening to container resize rather than window resize.
import Highcharts from 'highcharts';

Highcharts.wrap(Highcharts.Chart.prototype, 'setReflow', function (proceed, reflow) {
  let chart = this;

  proceed.call(this, reflow);

  if (reflow !== false && typeof ResizeObserver === 'function') {
    if (this.unbindReflow) {
      this.unbindReflow();
    }

    let ro = new ResizeObserver(function () {
      if (Object.keys(chart).length !== 0) {
        chart.reflow();
      }
    });

    ro.observe(this.renderTo);
  }
});

export { HighchartsVue };
