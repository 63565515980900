import { ref } from 'vue';

const key = ref(0);

export default function useApp() {
  async function refreshApp() {
    key.value += 1;
  }

  return {
    key,
    refreshApp,
  };
}
