/**
 * A simple debounce function that will only call the callback function
 * if it has not been re-called in the last `timeout` time.
 *
 * @param func
 * @param timeout
 * @returns {(function(...[*]): void)|*}
 */
export function debounce(func, timeout = 500) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

/**
 * Uppercase the first letter of the given string and optionally
 * lowercase the rest if `lowercaseRest` is true.
 *
 * @param string - The string to process
 * @param lowercaseRest - if true, lowercase the rest of the string
 * @returns {string}
 */
export function ucFirst(string, lowercaseRest = false) {
  return `${string.charAt(0).toUpperCase()}${lowercaseRest ? string.slice(1).toLowerCase() : string.slice(1)}`;
}

/**
 * Uppercase every first letter of the given string
 *
 * @param string - The string to process
 * @returns {*}
 */
export function ucWords(string) {
  return string
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

/**
 * Replace all occurrences of the given substring with a space.
 *
 * @param string
 * @param value
 * @returns {*}
 */
export function characterToSpace(string, value) {
  let regex = new RegExp(value, 'g');

  return string.replace(regex, ' ');
}

/**
 * A crude function that will "un-slug" a slugged string by replacing
 * the supplied value with spaces and capitalizing the first letter of
 * each word.
 *
 * @param {string} string - The slugged string to un-slug.
 * @param {string} value - The value to replace with spaces
 * @returns {string}
 */
export function unslug(string, value = '_') {
  return ucWords(characterToSpace(string, value));
}

/**
 * A function that takes a string and lowercase's it, then removes all non-alphanumeric characters
 * good for using when comparing strings to see if they match different variants of the same string
 * @param str
 * @returns {string}
 */
export function comparisonize(str) {
  return str.toLowerCase().replace(/[^a-zA-Z\d]/g, '');
}

export function addParamsToLocation(paramsToAdd) {
  let url = new URL(window.location);

  Object.entries(paramsToAdd).forEach(([key, value]) => {
    if (typeof value === 'object') {
      url.searchParams.set(`${key}[${Object.keys(value)[0]}]`, Object.values(paramsToAdd[key]));
    } else {
      url.searchParams.set(key, value);
    }
  });

  history.replaceState(history.state, null, decodeURI(url));
}

export function deleteParamsFromLocation(paramsToDelete) {
  const url = new URL(window.location);

  paramsToDelete.forEach((p) => {
    url.searchParams.delete(p);
  });

  history.replaceState(history.state, null, decodeURI(url));
}
