import ApiClient from '@/services/api-client';

export default {
  getAll(options = {}) {
    return ApiClient.get('campaigns', {
      params: options,
    });
  },
  getById(id, options = {}) {
    return ApiClient.get('campaigns/' + id, {
      params: options,
    });
  },
  create(params) {
    return ApiClient.post('campaigns', params);
  },
  updateById(id, params) {
    return ApiClient.put('campaigns/' + id, params);
  },
  getContactLists(id, options = {}) {
    return ApiClient.get('campaigns/' + id + '/contact-lists', {
      params: options,
    });
  },
  getCreativeProofPreview(id) {
    return ApiClient.get('campaigns/' + id + '/creative-proof/preview');
  },
  updateContactList(id, params) {
    return ApiClient.put('campaigns/' + id + '/contact-lists', params);
  },
  updateVanityDomain(id, params) {
    return ApiClient.put('campaigns/' + id + '/vanity-domains', params);
  },
  updateLander(id, params) {
    return ApiClient.put('campaigns/' + id + '/lander', params);
  },
  uploadContactList(id, params) {
    return ApiClient.post('campaigns/' + id + '/contact-lists', params);
  },
  deleteThumbnail(id) {
    return ApiClient.delete('campaigns/' + id + '/thumbnail');
  },
  deleteLander(id) {
    return ApiClient.delete('campaigns/' + id + '/lander');
  },
  updateCreative(id, params) {
    return ApiClient.put('campaigns/' + id + '/creative', params);
  },
  updatePhoneTracking(id, params) {
    return ApiClient.put('campaigns/' + id + '/phone-numbers', params);
  },
  updateReturnAddress(id, params) {
    return ApiClient.put('campaigns/' + id + '/return-address', params);
  },
  updateWebTracking(id, params) {
    return ApiClient.put('campaigns/' + id + '/web-tracking', params);
  },
  updateModules(id, params) {
    return ApiClient.put('campaigns/' + id + '/modules', params);
  },
  testPhone(id, params) {
    return ApiClient.post('campaigns/' + id + '/phone_test', params);
  },
  launch(params) {
    return ApiClient.post('launch_campaign', params);
  },
  duplicate(params) {
    return ApiClient.post('duplicate_campaign', params);
  },
  deleteById(id) {
    return ApiClient.delete('campaigns/' + id);
  },
  applyCouponCode(campaignId, params) {
    return ApiClient.post(`campaigns/${campaignId}/coupon/apply`, params);
  },
  calculatePrice(campaignId, params) {
    return ApiClient.post(`campaigns/${campaignId}/pricing-calculator`, params);
  },
  purchase(campaignId, params) {
    return ApiClient.post(`campaigns/${campaignId}/purchase`, params);
  },
  hideById(campaignId) {
    return ApiClient.patch(`campaigns/${campaignId}/hide`);
  },
  restoreById(campaignId) {
    return ApiClient.patch(`campaigns/${campaignId}/restore`);
  },
  cancelById(campaignId) {
    return ApiClient.patch(`campaigns/${campaignId}/cancel`);
  },
};
